import React, { FC, useState } from 'react';
import InputSign from "../inputSign/InputSign";
import { BUTTON_X, CHECK } from "../../constants/constants";
import { useTranslation } from "react-i18next";
import { remindUser } from '../../../api/api';
import { useDataProvider } from '../../../components/DataProvider';

interface ModalForgotPasswordinterface {
    setForgotPassword: (forgotPassword: boolean) => void
}

const ModalForgotPassword: FC<ModalForgotPasswordinterface> = ({ setForgotPassword }) => {
    const { t } = useTranslation();

    const { BackgroundColor, AccentColor } = useDataProvider();

    const [email, setEmail] = useState('')
    const [modalCode, setModalCode] = useState(false)
    const handleEmailChange = (e: string) => {
        setEmail(e);
    }

    const onSubmit = () => {
        setModalCode(true);
        remindUser({
            username: email
        })
    }

    return (
        <>
            {
                modalCode
                    ? <div
                        className={'tw3-absolute tw3-w-[100%] tw3-h-[100%] tw3-bottom-0 tw3-left-0 tw3-right-0 tw3-top-0 tw3-bg-black/50 tw3-flex tw3-items-center tw3-justify-center'}
                        onClick={() => setForgotPassword(false)}
                    >
                        <div
                            className={'tw3-relative tw3-max-w-[535px] tw3-p-[40px] tw3-rounded-[8px]'}
                            style={{
                                backgroundColor: BackgroundColor
                            }}
                        >
                            <div className={'tw3-flex tw3-ml-[58px]'}>
                                <img src={CHECK} alt="" className={'tw3-absolute tw3-left-[50px] tw3-top-[28%]'} />
                                <p className={'tw3-text-[28px] tw3-font-medium'}>{t('Patikrink savo paštą')}</p>
                            </div>
                            <div className={'tw3-ml-[58px]'}>
                                {t('Išsiuntėme el. laišką adresu su instrukcijomis.', { email })}
                            </div>
                        </div>
                    </div>
                    : <div
                        className={'tw3-absolute tw3-w-[100%] tw3-h-[100%] tw3-bottom-0 tw3-left-0 tw3-right-0 tw3-top-0 tw3-bg-black/50 tw3-flex tw3-items-center tw3-justify-center'}
                    >
                        <div
                            className={'tw3-relative tw3-max-w-[535px] tw3-p-[40px] tw3-rounded-[8px]'}
                            style={{
                                backgroundColor: BackgroundColor
                            }}
                        >
                            <div
                                className={'tw3-absolute tw3-right-[20px] tw3-top-[44px] tw3-cursor-pointer'}
                                onClick={() => setForgotPassword(false)}>
                                <img src={BUTTON_X} alt="" />
                            </div>
                            <div className={'tw3-text-[28px] tw3-font-medium'}>
                                {t('Atstatyti slaptažodį')}
                            </div>
                            <div className={'tw3-text-[16px] tw3-my-[24px]'}>
                                {t('Įveskite savo el. pašto adresą ir mes atsiųsime jums el. laišką su instrukcijom.')}
                            </div>
                            <InputSign title={t('El. paštas')} value={email} handleChange={handleEmailChange} />
                            <button
                                className={'tw3-py-[8px] tw3-px-[16px] tw3-mt-[27px] tw3-rounded-[4px] tw3-text-[#fff]'}
                                onClick={onSubmit}
                                style={{
                                    backgroundColor: AccentColor
                                }}
                            >{t('Atstatyti')}</button>
                        </div>
                    </div>
            }
        </>
    );
}

export default ModalForgotPassword;
