import { ChangeEvent, FC, useState } from "react"
import PrivacyPolicyCheckbox from "../../components/PrivacyPolicyCheckbox"
import { Button, Input, Wrapper } from "../components/index"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDataProvider } from "../../components/DataProvider";
import { doSignUp } from "../../api/front-api";

export const SignUp: FC = () => {
  const { SignUpShowFirstName, SignUpShowLastName, SignUpShowUsername, PrivacyPolicyUrl } = useDataProvider();

  const [error, setError] = useState('');

  const { t } = useTranslation();

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [tel, setTel] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [isVisible, SetIsVisible] = useState(false)

  const onChangeFirstName = (e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)
  const onChangeLastName = (e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)
  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
  const onChangeUsername = (e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)
  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
  const onChangePasswordRepeat = (e: ChangeEvent<HTMLInputElement>) => setPasswordRepeat(e.target.value)
  const onChangeTel = (e: ChangeEvent<HTMLInputElement>) => setTel(e.target.value)
  const onChangeVisible = () => SetIsVisible(!isVisible)

  const onSubmit = async () => {
    const _res = await doSignUp({
      email,
      username: SignUpShowUsername ? username : email,
      password,
      passwordRepeat,
      setError,
      firstName,
      lastName,
      phone: tel,
    })
    if (_res) {
      closeSignUp();
    }
  }

  const closeSignUp = () => {
    if (window.parent) {
      var event = new CustomEvent('CloseToolbarButtonIframe', {})
      window.parent.document.dispatchEvent(event)
    }
  }

  const disabled = !!PrivacyPolicyUrl && !isChecked;

  return (
    <div className="tw3-flex tw3-items-center tw3-justify-center tw3-h-screen lg:tw3-bg-slate-50">
      <Wrapper>
        <div className="tw3-mt-10 tw3-space-y-[24px]">
          <p className="tw3-text-3xl tw3-font-medium">{t('Sukurti paskyrą')}</p>
          <form className="tw3-mt-6">

            {(!!SignUpShowFirstName || !!SignUpShowLastName) &&
              <div className="tw3-flex tw3-gap-2">
                {!!SignUpShowFirstName &&
                  <div>
                    <p className="tw3-mb-2 tw3-text-sm tw3-font-semibold">{t('Vardas')}</p>
                    <Input onChangeValue={onChangeFirstName} placeholder={t('Vardas')} type="text" value={firstName} isValid={firstName.length > 5 || firstName.length === 0} />
                  </div>
                }
                {!!SignUpShowLastName &&
                  <div>
                    <p className="tw3-mb-2 tw3-text-sm tw3-font-semibold">{t('Pavardė')}</p>
                    <Input onChangeValue={onChangeLastName} placeholder={t('Pavardė')} type="text" value={lastName} isValid={lastName.length > 5 || lastName.length === 0} />
                  </div>
                }
              </div>
            }
            <div>
              <p className="tw3-mb-2 tw3-text-sm tw3-font-semibold">{t('El. paštas')}</p>
              <Input onChangeValue={onChangeEmail} placeholder={t('El. paštas')} type="email" value={email} isValid={email.length > 5 || email.length === 0} />
            </div>
            <div>
              <p className="tw3-mb-2 tw3-text-sm tw3-font-semibold">{t('Telefonas')}</p>
              <Input onChangeValue={onChangeTel} placeholder={t('Telefonas')} type="tel" value={tel} isValid={tel.length > 5 || tel.length === 0} />
            </div>
            {!!SignUpShowUsername &&
              <div>
                <p className="tw3-mb-2 tw3-text-sm tw3-font-semibold">{t('Prisijungimo vardas')}</p>
                <Input onChangeValue={onChangeUsername} placeholder={t('Prisijungimo vardas')} type="text" value={username} isValid={username.length > 5 || username.length === 0} />
              </div>
            }
            <div>
              <p className="tw3-mb-2 tw3-text-sm tw3-font-semibold">{t('Slaptažodis')}</p>
              <Input isVisible={isVisible} onChangeVisible={onChangeVisible} onChangeValue={onChangePassword} placeholder={t('Slaptažodis')} type="password" value={password} isValid={password.length > 5 || password.length === 0} />
            </div>
            <div>
              <p className="tw3-mb-2 tw3-text-sm tw3-font-semibold">{t('Pakartokite slaptažodį')}</p>
              <Input isVisible={isVisible} onChangeVisible={onChangeVisible} onChangeValue={onChangePasswordRepeat} placeholder={t('Pakartokite slaptažodį')} type="password" value={passwordRepeat} isValid={passwordRepeat.length > 5 || passwordRepeat.length === 0} />
            </div>
            {!!PrivacyPolicyUrl &&
              <div className="tw3-flex tw3-items-center">
                <PrivacyPolicyCheckbox isChecked={isChecked} onCheckedChange={setIsChecked} />
              </div>
            }
          </form>

          {!!error && <div className='tw3-duration-200 tw3-bg-red-50 tw3-text-red-700 tw3-p-2 tw3-rounded-md'>{t(error)}</div>}

          <Button onClick={onSubmit} className="tw3-mt-10" disabled={disabled}>{t('Sukurti paskyrą')}</Button>
          <div className="tw3-text-center tw3-mt-6">
            <Link to={`${process.env.REACT_APP_PUBLIC_URL}`} className="tw3-text-sm tw3-text-sky-700 " >{t('Turite paskyra?')}</Link>
          </div>
        </div>
      </Wrapper>
    </div>
  )
}