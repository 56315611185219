import React, { Fragment, useEffect, useState } from 'react'
import SimpleWebAuthnBrowser, { startRegistration } from '@simplewebauthn/browser';
import { LoginUser, TwoFaFidoVerifySignUpToken } from '../../api/api';
import { useTranslation } from 'react-i18next';
import { useDataProvider } from '../../components/DataProvider';


type Props = {
    twoFaFido: any

    authOptions: LoginUser,

    onSuccess: (token: string) => void,
}

export default function FidoComponent(props: Props) {
    const { t } = useTranslation('');

    const { AccentColor } = useDataProvider();

    const [isError, setIsError] = useState(false);

    const onSubmit = async () => {
        let attResp;
        try {
            // Pass the options to the authenticator and wait for a response
            attResp = await startRegistration(props.twoFaFido);
        } catch (error: any) {
            console.log({ error })
            // Some basic error handling
            if (error.name === 'InvalidStateError') {
                // elemError.innerText = 'Error: Authenticator was probably already registered by user';
            } else {
                // elemError.innerText = error;
            }

            // throw error;
            setIsError(true);
        }

        const res = await TwoFaFidoVerifySignUpToken(
            {
                authOptions: props.authOptions,

                response: attResp,
                secret: props.twoFaFido.challenge
            }
        )

        if (res.data.action === 'logged-in') {
            props.onSuccess(res.data.token);
        } else {
            setIsError(true);
        }

    }

    return (
        <Fragment>
            <ul className='tw3-list-disc'>
                <li>Insert your security key into an available USB port or follow the wireless pairing instructions if it's a Bluetooth-enabled device.</li>
                <li>The platform will prompt you with on-screen instructions. Typically, this involves pressing a button on your FIDO2 device or confirming the connection.</li>
                <li>Complete any additional steps required for authentication setup, such as verifying your identity through existing credentials.</li>
                <li>Confirm the registration of your FIDO2 security key. This may involve providing a name for the device to help identify it later.</li>
            </ul>


            {isError && <p className={'tw3-text-[#EF4444] tw3-text-[12px]'}>{t('Wrong token')}</p>}

            <button
                onClick={onSubmit}
                className={'tw3-w-[100%] tw3-py-[16px] tw3-text-[#ffffff]'}
                style={{
                    backgroundColor: AccentColor,
                }}
            >
                {t('Start')}
            </button>
        </Fragment>
    )
}
