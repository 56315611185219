import React, { Fragment } from 'react'
import { useSearchParams } from 'react-router-dom';
import { useDataProvider } from '../../../components/DataProvider'

export default function LeftPhoto() {
    const { ImageLeft } = useDataProvider();
    const [searchParams] = useSearchParams();

    if (!!searchParams.get('compact')) {
        return <Fragment />
    }

    return (
        <div className='tw3-hidden lg:tw3-block'>
            <img src={ImageLeft} alt="" className={'tw3-h-[100vh]'} />
        </div>
    )
}
