import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDataProvider } from '../../components/DataProvider';
import QRCode from 'qrcode'
import InputSign from '../../AuthV1/components/inputSign/InputSign';
import { LoginUser, TwoFaCodeVerifySignUpToken } from '../../api/api';

type Props = {
    twoFaCode: {
        secret: string,
        uri: string,
    }
    
    authOptions: LoginUser,

    onSuccess: (token: string) => void,
}

export default function CodeComponent(props: Props) {
    const { t } = useTranslation('');

    const { AccentColor } = useDataProvider();

    const [token, setToken] = useState('');
    const [qrUrl, setQrUrl] = useState('');
    const [isError, setIsError] = useState(false);

    const onSubmit = async () => {
        const res = await TwoFaCodeVerifySignUpToken(
            {
                secret: props.twoFaCode.secret,
                token,
                authOptions: props.authOptions,
            }
        )
        if (res.data.action === 'logged-in') {
            props.onSuccess(res.data.token);
        } else {
            setIsError(true);
        }
    }

    const generateQR = async (text: string) => {
        try {
            setQrUrl(await QRCode.toDataURL(text))
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (props.twoFaCode.uri) {
            generateQR(props.twoFaCode.uri);
        }
    }, [props.twoFaCode.uri])

    return (
        <Fragment>
            <ul className='tw3-list-disc'>
                <li>Install Authy or Google Authenticator from your app store.</li>
                <li>Scan the provided QR code using the app.</li>
                <li>Enter the generated code from Authy or Google Authenticator into the verification field.</li>
                <li>Confirm and complete the setup process. Your account is now fortified with Two-Factor Authentication.</li>
            </ul>

            {!!qrUrl && <div className='tw3-flex tw3-justify-center'><img width={240} src={qrUrl} /></div>}

            <InputSign onEnter={onSubmit} title={t('Verify code')} value={token} handleChange={setToken} isWrongCredentials={false} />

            {isError && <p className={'tw3-text-[#EF4444] tw3-text-[12px]'}>{t('Wrong token')}</p>}

            <button
                onClick={onSubmit}
                className={'tw3-w-[100%] tw3-py-[16px] tw3-text-[#ffffff]'}
                style={{
                    backgroundColor: AccentColor,
                }}
            >
                {t('Prisijungti')}
            </button>
        </Fragment>
    )
}
