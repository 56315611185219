import { FC } from 'react'
import { useDataProvider } from '../../components/DataProvider'

type Props = {
  children: JSX.Element
}
export const Wrapper: FC<Props> = ({ children }) => {
  const { ImageTop } = useDataProvider();

  return (
    <div className='tw3-m-0 lg:tw3-m-10 tw3-w-full lg:tw3-w-[600px] tw3-rounded tw3-py-11 tw3-shadow-none lg:tw3-shadow-lg tw3-px-4 lg:tw3-px-16 lg:tw3-bg-white'>
      <div>
        {ImageTop.map(url => <img src={url} className='tw3-mx-auto' alt="" />)}
      </div>
      {children}
    </div>
  )
}