import React, { ReactNode } from 'react'
import { useDataProvider } from '../DataProvider';

type Props = {
    children: ReactNode
}

export default function PopupWindow(props: Props) {
    const { BackgroundColor } = useDataProvider();

    return (
        <div
            className={'tw3-absolute tw3-w-[100%] tw3-h-[100%] tw3-bottom-0 tw3-left-0 tw3-right-0 tw3-top-0 tw3-bg-black/50 tw3-flex tw3-items-center tw3-justify-center'}
        >
            <div
                className={'tw3-relative tw3-max-w-[535px] tw3-p-[40px] tw3-rounded-[8px] tw3-max-h-[100vh] tw3-overflow-y-auto'}
                style={{
                    backgroundColor: BackgroundColor
                }}
            >
                {props.children}
            </div>
        </div>
    )
}
