import React from 'react'
import { useDataProvider } from '../../../components/DataProvider'
import { invertColor } from '../../../components/utils'
import LeftPhoto from './LeftPhoto'

type Props = {
    children: any
}

export default function Wrapper(props: Props) {
    const { BackgroundColor } = useDataProvider();
    return (
        <div
            className="tw3-flex tw3-items-center tw3-justify-items-center"
            style={{
                backgroundColor: BackgroundColor,
                color: invertColor(BackgroundColor, true)
            }}
        >
            <LeftPhoto />
            <div className="tw3-max-h-[100vh] tw3-overflow-y-auto tw3-py-10 tw3-flex-grow">
                <div className={'tw3-w-full tw3-p-4 lg:tw3-p-0 lg:tw3-pl-12 xl:tw3-pl-24'}>
                    <div className={'tw3-w-[100%]'}>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}
