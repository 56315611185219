import React, { useState } from 'react';
import { Link } from "react-router-dom";
import InputSign from "../../components/inputSign/InputSign";
import { useTranslation } from "react-i18next";
import PrivacyPolicyCheckbox from '../../../components/PrivacyPolicyCheckbox';
import TopLogo from '../../components/UI/TopLogo';
import Wrapper from '../../components/UI/Wrapper';
import { doSignUp } from '../../../api/front-api';
import { useDataProvider } from '../../../components/DataProvider';
import classNames from 'classnames';
import { Checkbox } from '../../../AuthV2/components';

function SignUp() {
    const { SignUpShowFirstName, SignUpShowLastName, SignUpShowUsername, PrivacyPolicyUrl, AccentColor } = useDataProvider();

    const [error, setError] = useState('');

    const { t } = useTranslation();

    const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);

    const [isEnable2Fa, setIsEnable2Fa] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const disabled = !!PrivacyPolicyUrl && !isPrivacyChecked;

    const onSubmit = async () => {
        if (disabled) {
            return;
        }
        const _res = await doSignUp({
            email,
            username: SignUpShowUsername ? username : email,
            password,
            passwordRepeat,
            setError,
            firstName,
            lastName,
            phone,
        })
        if (_res) {
            closeSignUp();
        }
    }

    const closeSignUp = () => {
        if (window.parent) {
            var event = new CustomEvent('CloseToolbarButtonIframe', {})
            window.parent.document.dispatchEvent(event)
        }
    }

    return (
        <Wrapper>
            <div className={'tw3-w-full lg:tw3-w-[350px] xl:tw3-w-[450px] tw3-space-y-[24px]'}>
                <TopLogo />

                <div className={'tw3-text-[28px]'}>{t('Sukurti paskyrą')}</div>

                {(!!SignUpShowFirstName || !!SignUpShowLastName) &&
                    <div className={'tw3-flex w-[100%]  '}>
                        {!!SignUpShowFirstName &&
                            <InputSign title={t('Vardas')} value={firstName} handleChange={setFirstName} />
                        }
                        {!!SignUpShowLastName &&
                            <InputSign title={t('Pavardė')} value={lastName} handleChange={setLastName} />
                        }
                    </div>
                }

                <InputSign title={t('El. paštas')} value={email} handleChange={setEmail} />
                <InputSign title={t('Telefonas')} value={phone} handleChange={setPhone} />

                {!!SignUpShowUsername &&
                    <InputSign title={t('Prisijungimo vardas')} value={username} handleChange={setUsername} />
                }

                <InputSign title={t('Slaptažodis')} type={"password"} value={password} handleChange={setPassword} />
                <InputSign title={t('Pakartokite slaptažodį')} type={"password"} value={passwordRepeat} handleChange={setPasswordRepeat} />

                <div className="tw3-flex tw3-items-center">
                    <Checkbox name='privacy' className="tw3-mr-3" isChecked={isEnable2Fa} onCheckedChange={() => setIsEnable2Fa(!isEnable2Fa)} />
                    <label htmlFor='privacy' className="tw3-text-sm">
                        {t('Enable 2Fa')}
                    </label>

                </div>

                {!!PrivacyPolicyUrl &&
                    <div className="tw3-flex tw3-items-center">
                        <PrivacyPolicyCheckbox isChecked={isPrivacyChecked} onCheckedChange={setIsPrivacyChecked} />
                    </div>
                }

                {!!error && <div className='tw3-duration-200 tw3-bg-red-50 tw3-text-red-700 tw3-p-2 tw3-rounded-md'>{t(error)}</div>}


                <button
                    onClick={onSubmit}
                    className={
                        classNames(
                            'tw3-w-[100%] tw3-py-[16px] tw3-text-[#ffffff]',
                            // { 'tw3-bg-[#0EA5E9]': !disabled },
                            { 'tw3-bg-[#0EA5E9]/50': disabled },
                        )
                    }
                    style={
                        !disabled ? {
                            backgroundColor: AccentColor,
                        } : {}
                    }
                >
                    {t('Sukurti paskyrą')}
                </button>

                <div
                    // className={'tw3-text-[#0EA5E9]'}
                    style={{
                        color: AccentColor,
                    }}
                >
                    <Link to={`${process.env.REACT_APP_PUBLIC_URL}`} >{t('Turite paskyra?')}</Link>
                </div>

                {/* <button onClick={closeSignUp}>
                    Close
                </button> */}
            </div>

        </Wrapper>
    );
}

export default SignUp;
