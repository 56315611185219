import { FC } from "react"
import classNames from 'classnames'

type Props = {
  children: string
  className?: string
  onClick: () => void,
  disabled?: boolean,
}

export const Button: FC<Props> = ({ children, className, onClick, disabled }) => {

  const _onClick = () => {
    if (disabled) {
      return;
    }
    onClick();
  }

  return (
    <button onClick={_onClick} className={
      classNames(
        ` tw3-text-white focus:tw3-outline-none tw3-rounded-md tw3-duration-200 tw3-py-3 tw3-w-full ${className}`,
        { 'tw3-bg-sky-500 hover:tw3-bg-sky-700': !disabled },
        { 'tw3-bg-sky-500/50': disabled },
      )}>{children}</button>
  )
}