import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import InputSign from "../../components/inputSign/InputSign";
import { useTranslation } from "react-i18next";
import TopLogo from '../../components/UI/TopLogo';
import Wrapper from '../../components/UI/Wrapper';
import { changePassword } from '../../../api/api';
import { useDataProvider } from '../../../components/DataProvider';

function Update() {
    const { AccentColor } = useDataProvider();

    const [searchParams] = useSearchParams();

    const { t } = useTranslation();

    const [error, setError] = useState('');
    const [changed, setChanged] = useState(false);

    const [isWrongCredentials, setIsWrongCredentials] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const onSubmit = () => {
        if (!password) {
            setError("Įveskite slaptažodį");
        }
        if (password !== passwordRepeat) {
            setError("Slaptažodžiai nesutampa");
            return;
        }

        let token = '';
        if (!!searchParams.get('token')) {
            // @ts-ignore
            token = searchParams.get('token');
        }

        changePassword({
            password,
            token,
        }).then(res => {
            if (res.data.success === 1) {
                setChanged(true);
                setError('');
            } else {
                setError('Klaida')
            }
        })
    }

    return (
        <Wrapper>
            <TopLogo />
            <div className={'tw3-text-[28px]'}>{t('Keisti slaptažodį')}</div>
            <div className={'tw3-w-full lg:tw3-w-[350px] xl:tw3-w-[450px] tw3-space-y-[24px]'}>

                <InputSign title={t('Slaptažodis')} type={"password"} value={password} handleChange={setPassword} isWrongCredentials={isWrongCredentials} />

                <InputSign title={t('Pakartokite slaptažodį')} type={"password"} value={passwordRepeat} handleChange={setPasswordRepeat} isWrongCredentials={isWrongCredentials} />

                {!!error && <div className='tw3-duration-200 tw3-bg-red-50 tw3-text-red-700 tw3-p-2 tw3-rounded-md'>{t(error)}</div>}

                {!!changed && <div className='tw3-duration-200 tw3-bg-teal-50 tw3-text-teal-700 tw3-p-2 tw3-rounded-md'>{t('Slaptažodis pakeistas')}</div>}

                <div>
                    <button
                        onClick={onSubmit}
                        className={'tw3-w-[100%] tw3-py-[16px] tw3-text-[#ffffff]'}
                        style={{
                            backgroundColor: AccentColor,
                        }}
                    >
                        {t('Keisti slaptažodį')}
                    </button>
                </div>
            </div>
        </Wrapper>
    );
}

export default Update;
